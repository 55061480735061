.details {
  max-width: 1080px;
  margin: auto;
  margin-top: 2.2rem;
  margin-bottom: 1rem;
}

@media (max-width: 450px) {
  .details {
    margin-top: 0.5rem;
  }
}
