.home {
  max-width: 1080px;
  margin: auto;
  margin-top: 4.5rem;
  margin-bottom: 2rem;
}

.main-slideshow .carousel-inner {
  max-height: 500px;
  text-align: center;
  margin: auto;
  padding: auto;
}

.main-slideshow .carousel-indicators li {
  width: 0.7rem;
  height: 0.7rem;
  border-radius: 50%;
}

@media (max-width: 450px) {
  .home {
    margin-top: 3.3rem;
  }
}
