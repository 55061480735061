.page-footer {
  bottom: 0;
  /* position: relative; */
  max-width: 1200px;

  margin: auto;
}
footer a {
  color: white;
  font-weight: normal;
  text-decoration: none;
}
footer a:hover {
  color: rgb(0, 0, 0);
  font-weight: bold;
  text-decoration: none;
}
footer hr {
  background-color: black;
  height: 1px;
  margin-top: 0px;
}
footer p {
  margin-bottom: 1rem;
}

.social-icon a {
  margin-right: 7%;
}
.social-icon {
  margin-bottom: 0.8rem;
}

.facebook {
  color: white;
}
.facebook:hover {
  color: rgb(18, 104, 233);
}
.instagram {
  color: white;
}
.instagram:hover {
  color: #e4405f;
}
/* .copyright {
  padding-left: 7%;
} */

.footer-logo {
  width: 10rem;
}
