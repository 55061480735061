body {
  /* max-width: 1200px; */
  margin: auto;
  padding: auto;
}

.page-body {
  max-width: 1080px;
  margin: auto;
  margin-top: 4.8rem;
  margin-bottom: 60px;
}

@media (max-width: 450px) {
  .page-body {
    margin-top: 3.3rem;
  }
}

.page-body-admin {
  max-width: 1080px;
  margin: auto;
  margin-top: 70px;
  margin-bottom: 60px;
}

.main-title hr {
  background-color: black;
  height: 1px;
  margin-top: 5px;
}

.main-title {
  font-weight: bold;
  margin: 60px 40px 20px 0px;
  font-size: 20px;
}

.input_btn {
  display: none;
}
#pic1 {
  display: none;
}

.form-group img {
  display: none;
  max-width: 100%;
}
.cursor-pointer {
  cursor: pointer;
}
.add-product .card-body {
  padding: 3px;
}
.wishListCardBtn:hover {
  background: gray;
  /* color: black; */
}
.cart .wish-list {
  color: gray;
  font-size: 1.2rem;
}
.cart .wish-list:hover {
  font-size: 1.5rem;
}
.remove {
  color: black;
  font-size: 1.2rem;
}
.remove:hover {
  font-size: 1.5rem;
  color: black;
}
.placeOrder .remove {
  color: black;
  font-size: 1.2rem;
}
.placeOrder .remove:hover {
  font-size: 1.5rem;
}

.count-button {
  background-color: gray;
  border: none;
  width: 30px;
  height: 30px;
  color: white;
  font-weight: bold;
  margin: 5px;
  padding: auto;
}
.count-button:hover {
  background-color: black;
}
.count {
  width: 30px;
  height: 30px;
  text-align: center;
  margin: 5px 0 5px 0;
}
.delivery .search-box {
  position: relative;
  background: white;
  height: 4rem;
  border-radius: 4rem;
  max-width: 30rem;
  border: solid 1px black;
}

.delivery .search-btn {
  color: black;
  float: right;
  width: 3.9rem;
  height: 3.9rem;
  border-radius: 50%;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.delivery .search-btn:hover {
  background: black;
  color: white;
  text-decoration: none;
}

.delivery .search-txt {
  background: none;
  border: none;
  outline: none;
  float: left;
  padding: 0;
  color: black;
  font-size: 1rem;
  line-height: 4rem;
  margin-left: 1rem;
  margin-right: 1rem;
  width: 60%;
}
