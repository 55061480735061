.navbar {
  max-width: 1200px;
  margin: auto;
}
.nav-logo {
  max-width: 150px;
  height: 2.2rem;
  margin: 1px;
}
.nav-c-item {
  max-width: 140px;
}

.navbar .search-box {
  position: relative;
  background: rgba(148, 147, 147, 01);
  height: 30px;
  border-radius: 30px;
  max-width: 300px;
}
.navbar .search-btn:hover {
  background: white;
  color: black;
  text-decoration: none;
}

.navbar .search-btn {
  color: white;
  float: right;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar .search-txt {
  background-color: rgba(148, 147, 147, 0.1);
  border: none;
  outline: none;
  float: left;
  padding: 0;
  color: white;
  font-size: 16px;
  line-height: 30px;
  margin-left: 15px;
  margin-right: 10px;
  width: 70%;
}
.navbar .wish-list {
  color: white;
}
.navbar .wish-list:hover {
  color: rgb(250, 8, 8);
}
.nav-link {
  color: white;
  font-weight: normal;
}

.nav-link:hover {
  color: rgb(255, 255, 255);
  font-weight: bold;
}

.nav-dropdown {
  position: relative;
  display: inline-block;
}

.nav-dropdown .nav-dropbtn {
  border: none;
  outline: none;
  color: rgb(209, 208, 208);
  background-color: inherit;
}

.nav-dropdown-content {
  display: none;
  position: absolute;
  background-color: #dbdada;
  width: 180px;
  padding: 5px;
  z-index: 1000;
}

.nav-dropdown-content .dropdown-item {
  color: black;
  padding: 5px 10px;
  text-decoration: none;
  display: block;
  text-align: center;
}

.nav-dropdown-content .dropdown-item:hover {
  background-color: rgb(255, 255, 255);
}

.nav-dropdown:hover > .nav-dropdown-content {
  display: block;
}

.nav-dropbtn:hover {
  color: rgb(255, 255, 255);
}

.navbar .checkout {
  text-align: center;
  padding: 10px;
}
.navbar .checkout-btn {
  background: rgb(0, 0, 0);
  color: white;
  border: none;
  padding: 5px;
}

.navbar .checkout-btn:hover {
  color: rgb(255, 255, 255);
  background: gray;
  border: none;
  padding: 5px;
}

@media (max-width: 450px) {
  .nav-link {
    font-size: 0.75rem;
  }
}
