.card {
  /* max-width: 250px; */
  max-height: 18rem;
}

:hover .card {
  border: 0.04rem solid rgba(0, 0, 0, 0.2);
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.2);
}
:hover .card-footer {
  background: rgba(247, 247, 247);
}

.img-container {
  position: relative;
  overflow: hidden;
  text-align: center;
}
.card-img-top {
  transition: all 0.5s linear;
  width: auto;
  max-height: 15rem;
}
.img-container:hover .card-img-top {
  transform: scale(1.3);
}
.cart-btn {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0.2rem 0.4rem;
  background: gray;
  border: none;
  color: black;
  font-size: 1.4rem;
  border-radius: 0.5rem 0 0 0;
  transform: translate(100%, 100%);
  transition: all 0.5s linear;
}
.img-container:hover .cart-btn {
  transform: translate(0, 0);
}
.cart-btn:hover {
  background-color: rgb(0, 0, 0);
  color: white;
  cursor: pointer;
  transition: 0ms;
}

.wish-btn {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0.2rem 0.4rem;
  background: gray;
  border: none;
  color: black;
  font-size: 1.4rem;
  border-radius: 0 0.5rem 0 0;
  transform: translate(-100%, 100%);
  transition: all 0.5s linear;
}
.img-container:hover .wish-btn {
  transform: translate(0, 0);
}

.wish-btn:hover {
  background-color: black;
  color: white;
  cursor: pointer;
  transition: 0ms;
}
