.products-new {
  text-align: center;
  margin: auto;
  padding: auto;
  max-height: 19rem;
}

.control-icon {
  color: black;
  font-size: 1.5rem;
}

@media (min-width: 768px) and (max-width: 991px) {
  /* Show 4th slide on md if col-md-4*/
  .products-new
    .carousel-inner
    .active.col-md-4.carousel-item
    + .carousel-item
    + .carousel-item
    + .carousel-item {
    position: absolute;
    top: 0;
    right: -33.3333%; /*change this with javascript in the future*/
    z-index: -1;
    display: block;
    visibility: visible;
  }
}
@media (min-width: 76px) and (max-width: 768px) {
  /* Show 3rd slide on sm if col-sm-6*/
  .products-new
    .carousel-inner
    .active.col-sm-6.carousel-item
    + .carousel-item
    + .carousel-item {
    position: absolute;
    top: 0;
    right: -50%; /*change this with javascript in the future */
    z-index: -1;
    display: block;
    visibility: visible;
  }
}
@media (min-width: 76px) {
  .products-new .carousel-item {
    margin-right: 0;
  }
  /* show 2 items */
  .products-new .carousel-inner .active + .carousel-item {
    display: block;
  }
  .products-new
    .carousel-inner
    .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left),
  .products-new
    .carousel-inner
    .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left)
    + .carousel-item {
    transition: none;
  }
  .products-new .carousel-inner .carousel-item-next {
    position: relative;
    transform: translate3d(0, 0, 0);
  }
  /* left or forward direction */
  .products-new
    .active.carousel-item-left
    + .carousel-item-next.carousel-item-left,
  .products-new .carousel-item-next.carousel-item-left + .carousel-item,
  .products-new
    .carousel-item-next.carousel-item-left
    + .carousel-item
    + .carousel-item {
    position: relative;
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }
  /* farthest right hidden item must be also positioned for animations */
  .products-new .carousel-inner .carousel-item-prev.carousel-item-right {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    display: block;
    visibility: visible;
  }
  /* right or prev direction */
  .products-new
    .active.carousel-item-right
    + .carousel-item-prev.carousel-item-right,
  .products-new .carousel-item-prev.carousel-item-right + .carousel-item,
  .products-new
    .carousel-item-prev.carousel-item-right
    + .carousel-item
    + .carousel-item {
    position: relative;
    transform: translate3d(100%, 0, 0);
    visibility: visible;
    display: block;
    visibility: visible;
  }
}
/* MD */
@media (min-width: 768px) {
  /* show 3rd of 3 item slide */
  .products-new .carousel-inner .active + .carousel-item + .carousel-item {
    display: block;
  }
  .products-new
    .carousel-inner
    .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left)
    + .carousel-item
    + .carousel-item {
    transition: none;
  }
  .products-new .carousel-inner .carousel-item-next {
    position: relative;
    transform: translate3d(0, 0, 0);
  }
  /* left or forward direction */
  .products-new
    .carousel-item-next.carousel-item-left
    + .carousel-item
    + .carousel-item
    + .carousel-item {
    position: relative;
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }
  /* right or prev direction */
  .products-new
    .carousel-item-prev.carousel-item-right
    + .carousel-item
    + .carousel-item
    + .carousel-item {
    position: relative;
    transform: translate3d(100%, 0, 0);
    visibility: visible;
    display: block;
    visibility: visible;
  }
}
/* LG */
@media (min-width: 991px) {
  /* show 4th item */
  .products-new
    .carousel-inner
    .active
    + .carousel-item
    + .carousel-item
    + .carousel-item {
    display: block;
  }
  .products-new
    .carousel-inner
    .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left)
    + .carousel-item
    + .carousel-item
    + .carousel-item {
    transition: none;
  }
  /* Show 5th slide on lg if col-lg-3 */
  .products-new
    .carousel-inner
    .active.col-lg-3.carousel-item
    + .carousel-item
    + .carousel-item
    + .carousel-item
    + .carousel-item {
    position: absolute;
    top: 0;
    right: -25%; /*change this with javascript in the future*/
    z-index: -1;
    display: block;
    visibility: visible;
  }
  /* left or forward direction */
  .products-new
    .carousel-item-next.carousel-item-left
    + .carousel-item
    + .carousel-item
    + .carousel-item
    + .carousel-item {
    position: relative;
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }
  /* right or prev direction //t - previous slide direction last item animation fix */
  .products-new
    .carousel-item-prev.carousel-item-right
    + .carousel-item
    + .carousel-item
    + .carousel-item
    + .carousel-item {
    position: relative;
    transform: translate3d(100%, 0, 0);
    visibility: visible;
    display: block;
    visibility: visible;
  }
}
